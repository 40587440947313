*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  display: inline-block;
  max-width: 100%;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #22252a;
  background-color: rgb(18, 18, 18);
}

.homepage-slider {
  max-width: 350px;
}

.homepage-slider .control-dots {
  bottom: -90px !important;
}

.carousel.carousel-slider {
  overflow: unset !important;
}

.homepage-slider .control-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.homepage-slider .control-dots li {
  border: 1px solid #00cfc8;
  width: 16px !important;
  height: 16px !important;
  box-shadow: none !important;
}

.homepage-slider .control-dots li.selected {
  width: 24px !important;
  height: 24px !important;
  background-color: #00cfc8 !important;
}

#customgpt_chat {
  padding: 0px 388px 0px;
  height: 700px;
}
